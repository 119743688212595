body {
    background: rgba(245, 246, 250);
    font-family: Nunito, sans-serif;
    height: 100vh;
    width: 100%;
    text-align: center;
  }
  
  h2 {
    font-family: Nunito, sans-serif;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    margin-top: 4rem;
    color: #364a63;
  }
  
  .nk-block-des {
    color: #526484;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 390px;
  }
  .form-label {
    color: #344357;
    font-size: .875rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

label {
    cursor: pointer;
}
label {
    display: inline-block;
}
  .text_area {
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column; /* Changed to column */
    align-items: center; /* Centered vertically */
    position: relative;
  }
  
  .text_input {
    appearance: none;
    font-size: 0.9375rem;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dbdfea;
    color: #3c4d62;
    display: block;
    font-weight: 400;
    line-height: 1.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    padding: 0.6875rem 1rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .btn-block {
    justify-content: center;
    width: 100%;
  }
  
  .btn {
    align-items: center;
    display: inline-flex;
    letter-spacing: 0.02em;
    position: relative;
    background: #b58ded;
    color: #fff;
    margin-bottom: 4.25rem;
    border-radius: 5px;
    border: none;
  }
  
  .btn:hover {
    background-color: #9f72d8;
  }
  
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border: 1px solid #dbdfea;
    width: 90%;
    max-width: 500px;
    margin: 10% auto;
    padding: 20px;
    border-radius: 2px;
  }
  
  .link {
    color: #0e4579;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: left; /* Align error messages to the left */
    margin-left: 5px; /* Add left margin to separate from input field */
  }
  
  @media (max-width: 600px) {
    h2 {
      font-size: 28px;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  
    .login {
      width: 87%;
      padding: 10px;
      /* max-width: 350px; */
      margin-top: 15%;
    }
  
    .btn {
      width: 90%;
    }
  
    .text_input {
      font-size: 16px;
    }
  }
  