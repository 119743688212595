.users-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    min-height: 100vh;
    background: linear-gradient(135deg, #f3e7e9 0%, #e3eeff 100%);
    /* text-align: center; */
    font-family: 'Segoe UI', sans-serif;
  }

 .users-container  h1 {
    font-family: Nunito, sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: #364a63;
  }
  
  .user-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .user-panel {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .user-panel h3 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .user-panel p {
    margin: 8px 0;
    color: #666;
  }
  

  .user-badge {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    background-color: #b58ded;
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .delete-button {
    position: relative;
    bottom: 10px;
    right: 10px;
    background-color: #ff5c5c;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .delete-button:hover {
    background-color: #d64141;
  }

  .users-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adjust margin as needed */
  }
  
  .users-header h1 {
    margin: 0; /* Remove default margin */
    text-align: center;
  }
  
  .add-user-button {
    background-color: #b58ded; /* Example background color */
    color: #fff; /* Example text color */
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .add-user-button:hover {
    background-color: #9f72d8; /* Darker background color on hover */
  }
  
  .update-btn {
    position: relative;
    bottom: 10px;
    right: 10px;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .user-btn:hover {
    background-color: #9f72d8;
  }
  .user-btn{
    background-color: #b58ded;
    margin-right: 20px;
  }

  .pwd-btn:hover {
    background-color: #acafb6;
  }
  .pwd-btn{
    background-color: #b7bac2;
  }

  @media (max-width: 600px) {

  
    .delete-button {
      width: 20%;
    }
    .add-user-button {
        width: 20%;
      }

      .update-btn{
        width: 30%;
      }
  
  
  }