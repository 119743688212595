/* src/App.css */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f3e7e9 0%, #e3eeff 100%);
  padding: 20px;
  text-align: center;
  font-family: 'Segoe UI', sans-serif;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 1px solid #dbdfea;
  width: 90%;
  max-width: 500px;
  margin: 10% auto;
  padding: 20px;
  border-radius: 20px;
}

h1 {
  font-family: 'Segoe UI', bold;
  font-size: 35px;
  margin-bottom: 2rem;
  color: #333;
}

.input-label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
  text-align: left;
  /* Ensure label aligns to the left */
  margin-left: 10px;
  /* Add some space from the left edge */
}

.text-input {
  padding: 15px;
  font-size: 1.2em;
  border-radius: 22px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  width: calc(100% - 20px);
  /* Make space for padding and borders */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-left: 10px;
  /* Align with label */
}

.text-input:focus {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

button {
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 22px;
  border: none;
  background: #b58ded;
  color: #fff;
  margin: 10px 0;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(181, 141, 237, 0.3);
}

button:hover {
  background: #9f72d8;
  box-shadow: 0 6px 12px rgba(181, 141, 237, 0.5);
}

/* App.css */
.qrdisplay {
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-right: 5px;
  padding-left: 5px;
  background: #fff;
  border-radius: 22px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  /* Use flexbox for better alignment */
  justify-content: center;
  /* Center align content horizontally */
  width: 300px;
  height: 300px;
}





.qrdisplay:hover {
  transform: scale(1.05);
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 600px) {
  .homepage-container {
    max-width: 90%;
    padding: 10px;
    margin-top: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .input-label {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .text-input {
    width: calc(100% - 20px);
    font-size: 1em;
  }

  button {
    width: 100%;
    font-size: 1em;
    padding: 12px 20px;
  }

  .qrdisplay {
    /* width: 100%; */
    margin-bottom: 10px;
    margin-top: 0px;
    padding: 0px;
    max-width: 300px;
    height: 300px;
  }
}

/* Base Navbar Styles */
.navbar {
  width: 100%;
  height: 70px;
  background-color: #b58ded;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Space between the elements */
  font-family: Nunito, sans-serif;
  font-size: 13px;
  padding: 0 20px;
  /* Add some padding for spacing */
  box-sizing: border-box;
  /* Ensure padding is included in width */
}

.navbar .links {
  display: flex;
  align-items: center;
}

.navbar .links a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.navbar .loggedInContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Ensure content is aligned to the end */
}

.navbar button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  top: 30px;
  background-color: #f4bd0e;
  border: none;
  /* Remove default button borders */
  border-radius: 5px;
  /* Rounded corners */
  color: white;
  /* Button text color */
  font-size: 14px;
  margin-left: 10px;
  /* Space between button and text */
  margin-right: 10px;
  /* Ensure button doesn't touch the edge */
}

.navbar h1 {
  margin: 0 10px;
  color: white;
  font-weight: lighter;
  font-size: 16px;
  /* Adjust font size */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
    /* Adjust height for content */
    padding: 10px;
    /* Reduce padding */
  }

  .navbar .links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    /* Space below links */
  }

  .navbar .links a {
    margin-left: 0;
    /* Remove margin left */
    margin-bottom: 10px;
    /* Add margin below each link */
    font-size: 16px;
    /* Increase font size */
  }

  .navbar .loggedInContainer {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }

  .navbar button {
    width: 80px;
    /* Smaller button width */
    height: 35px;
    /* Smaller button height */
    margin-left: 0;
    /* Remove left margin */
    top: 10px;
  }

  .navbar h1 {
    font-size: 14px;
    /* Smaller font size */
    text-align: center;
    /* Center text */
    width: 100%;
    margin: 0;
    /* Remove margin */
  }

  .navbar .loggedInContainer {
    height: 50px;
  }
}

/* Media Query for Extra Small Devices */
@media (max-width: 480px) {
  .navbar {
    padding: 5px;
    /* Reduce padding further */
  }

  .navbar .links a {
    font-size: 14px;
    /* Further reduce font size */
  }

  .navbar button {
    width: 70px;
    /* Even smaller button width */
    height: 30px;
    /* Even smaller button height */
    font-size: 12px;
    /* Smaller font size */
    top: 10px;
  }

  .navbar h1 {
    font-size: 12px;
    /* Smaller font size */
  }

  .navbar .loggedInContainer {
    height: 50px;
  }
}